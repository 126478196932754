import { gql } from '@guuru/graphql-common/gql';

const expertInvitation = gql(`#graphql
  query GetExpertInvitation($uid: ID!) {
    expertInvitation(uid: $uid) {
      uid
      name
      email
      role
      partner {
        id
        name
        settings {
          id
          security {
            id
            signinProviders
          }
        }
      }
      createdAt
      categories
    }
  }
`);

export default expertInvitation;
