import React from 'react';
import { Col, Row, Space } from 'antd';

const ProductImage = function () {
  return (
    <Row type="flex" justify="center" style={{ padding: 20 }}>
      <Col span={24} xs={24} sm={24}>
        <Space direction="vertical" block align="center" style={{ width: '100%' }}>
          <img
            src="https://cdn.guuru.com/assets/images/humanize-your-brand.png"
            width="100%"
            alt="Guuru - Humanize your brand"
          />
        </Space>
      </Col>
    </Row>
  );
};

export default ProductImage;
