import { gql } from '@apollo/client';

export default gql`
  query GetExpertProfile($id: ID!) {
    expert(id: $id) {
      id
      name
      photoUrl
      email
      locale
      isEmailVerified
      account {
        id
        tosAcceptedAt
      }
      pushToken
      pushTokenWeb
      roles {
        id
        role
        partner {
          id
          name
        }
      }
    }
  }
`;
