import { gql } from '@guuru/graphql-common/gql';

export default gql(`#graphql
  mutation RemoveExpertFromRole(
    $expertId: ID!
    $partnerId: ID!
    $role: PossibleRoles!
  ) {
    removeExpertFromRole(
      expertId: $expertId
      partnerId: $partnerId
      role: $role
    ) {
      id
      partners {
        edges {
          node {
            id
          }
          roles
        }
      }
    }
  }
`);
