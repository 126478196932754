import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useMatch } from 'react-router-dom';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Typography,
} from 'antd';
import {
  GoogleOutlined,
  LockOutlined,
  MailOutlined,
  WindowsFilled,
} from '@ant-design/icons';
import log from '@guuru/logger-web';
import { parse } from '@guuru/url-common';
import { error } from '@guuru/react-message';
import {
  signInWithPassword,
  signInWithGoogle,
  signInWithMicrosoft,
} from '@guuru/auth-web';
import CreateAccount from '../CreateAccount';
import ErrorMessages from '../ErrorMessages';
import FormItem from '../FormItem';
import ForgotPassword from '../ForgotPassword';

const errorMessages = {
  'auth/wrong-password':
    "Your password doesn't seem correct. Please try again.",
  'auth/user-not-found':
    "Your email doesn't seem correct. Please try again.",
  'auth/too-many-requests':
    'Your account is temporarily blocked. Please try again later.',
  'auth/access-denied':
    'You are not authorized to access.',
  'auth/account-exists-with-different-credential':
    'You have signed in with the wrong method for your account.',
};

const { Paragraph, Text } = Typography;

const Login = function ({
  login = () => {},
  signInErrorCode = null,
  origin = null,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isForgotPassword = useMatch('/signin/forgot-password');
  const [submitError, setSubmitError] = useState(null);
  const errorMessage = errorMessages[submitError || signInErrorCode]
    ?? 'Please try again later or contact Guuru Support';

  const urlLocation = parse(window.location.search);
  const isCreateAccount = !!urlLocation?.id;

  if (isCreateAccount) {
    return <CreateAccount />;
  }

  const signIn = async (signInFn, credentials = []) => {
    try {
      await signInFn(...credentials);
      login();
    } catch (err) {
      switch (err.message) {
        case 'auth/cancelled-popup-request':
        case 'auth/popup-closed-by-user':
          return;
        case 'auth/wrong-password':
        case 'auth/user-not-found':
        case 'auth/account-exists-with-different-credential':
          break;
        default:
          log.error(`Error authenticating user: ${error.message}`);
      }
      setSubmitError(err.message);
    }
  };

  const handleSubmit = async ({ email, password }) => {
    try {
      setIsSubmitting(true);
      await signIn(signInWithPassword, [email, password]);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isForgotPassword) {
    return <ForgotPassword />;
  }

  return (
    <>
      {(submitError || signInErrorCode)
        && <ErrorMessages message={errorMessage} />}
      <Paragraph>Sign in to your account</Paragraph>
      <Form
        layout="vertical"
        className="login-form"
        onFinish={handleSubmit}
      >
        <FormItem
          name="email"
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: 'Enter your email address',
            },
            {
              type: 'email',
              message: 'Enter a valid email address',
            },
          ]}
        >
          <Input
            size="large"
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Your Email"
            autoComplete="username"
            autoFocus
          />
        </FormItem>
        <FormItem
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password',
            },
          ]}
        >
          <Input.Password
            size="large"
            type="password"
            placeholder="Your Password"
            autoComplete="current-password"
            prefix={
              <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
            }
          />
        </FormItem>
        <FormItem>
          <Row gutter={[16, 8]}>
            <Col span={24} lg={12}>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
              >
                Sign in
              </Button>
            </Col>
            <Col span={24} lg={12}>
              <Link to="forgot-password">
                <Button block>
                  Forgot password?
                </Button>
              </Link>
            </Col>
          </Row>
        </FormItem>
        <Col span={24} lg={24}>
          <Divider plain>or sign in with</Divider>
          <Space direction="vertical" style={{ display: 'flex' }}>
            <Button
              block
              icon={<GoogleOutlined />}
              onClick={() => signIn(signInWithGoogle)}
            >
              Google
            </Button>
            <Button
              block
              icon={<WindowsFilled />}
              onClick={() => signIn(signInWithMicrosoft)}
            >
              Microsoft
            </Button>
          </Space>
        </Col>
        {!origin && (
          <Col style={{ textAlign: 'center' }}>
            <Divider plain />
            <Text>
              Don&apos;t have a Guuru account?<br />
              <Link to="/signup">Sign Up Now</Link>
            </Text>
          </Col>
        )}
      </Form>
    </>
  );
};

Login.propTypes = {
  login: PropTypes.func,
  origin: PropTypes.string,
  signInErrorCode: PropTypes.string,
};

export default Login;
