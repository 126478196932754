import React from 'react';
import { Badge, ConfigProvider } from 'antd';
import { colors } from '@guuru/constants-common';
import BlueLines from './assets/BlueLines.svg';

const getBgColor = (score: number, inverted: boolean): string => {
  if (score === 0) return colors.gray;
  return inverted ? colors.white : colors.blue;
};

const getTextColor = (score: number, inverted: boolean): string => {
  if (score === 0) return colors.white;
  return inverted ? colors.blue : colors.white;
};

type Props = {
  score: number;
  inverted?: boolean;
  height?: number;
  fontSize?: number;
  showLines?: boolean;
};

const ScoreBubble = function ({
  score,
  inverted = false,
  height = 32,
  fontSize = 13,
  showLines = false,
}: Props) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Badge: {
            indicatorHeight: height,
            textFontSize: fontSize,
          },
        },
      }}
    >
      {showLines && (
        <BlueLines
          width={20}
          height={20}
          style={{ position: 'absolute', left: '30px', top: '-10px' }}
        />
      )}
      <Badge
        showZero
        overflowCount={1000}
        count={score}
        color={getBgColor(score, inverted)}
        style={{ color: getTextColor(score, inverted) }}
      />
    </ConfigProvider>
  );
};

export default ScoreBubble;
