import { gql } from '@guuru/graphql-common/gql';

// eslint-disable-next-line import/prefer-default-export
export const PARTNER_FIELDS = gql(`#graphql
  fragment PartnerFields on Partner {
    id
    name
    description
    settings {
      id
      appearance {
        chatWidgetPhotoUrl
      }
      community {
        id
        recruiting
      }
    }
  }
`);
