import React from 'react';
import { useSuspenseQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import useExpertPartners from '../hooks/useExpertPartners';
import GET_COMMUNITY_DETAILS from './queries/getCommunityDetails';
import PartnerCard from './PartnerCard';
import JoiningCards from './JoiningCards';
import JoinedCards from './JoinedCards';
import CommunityButtons from './CommunityButtons';

const Community = function ({ partnerId }) {
  const {
    loading,
    expertPartners,
    submissions,
  } = useExpertPartners(true);

  const {
    data: { partner } = {},
  } = useSuspenseQuery(GET_COMMUNITY_DETAILS, { variables: { id: partnerId } });

  if (loading) return null;

  const joinedPartner = expertPartners
    .find((expertPartner) => expertPartner.node.id === partnerId);

  const hasPendingSubmissions = submissions
    .some((submission) => submission.node.id === partnerId);

  const {
    name,
    description,
    settings: {
      appearance: {
        chatWidgetPhotoUrl,
      } = {},
    } = {},
  } = partner;

  return (
    <>
      <PartnerCard
        name={name.en}
        description={!joinedPartner ? description?.en : ''}
        avatarUri={chatWidgetPhotoUrl}
      />
      {!joinedPartner && !hasPendingSubmissions ? (
        <JoiningCards partner={partner} />
      ) : (
        <>
          <CommunityButtons partner={joinedPartner} />
          <JoinedCards partner={joinedPartner} />
        </>
      )}
    </>
  );
};

Community.propTypes = {
  partnerId: PropTypes.string.isRequired,
};

export default Community;
