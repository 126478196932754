import PropTypes from 'prop-types';
import React from 'react';
import { Button, Tooltip } from 'antd';
import { error, success } from '@guuru/react-message';
import log from '@guuru/logger-web/src/logger';
import { useMutation } from '@apollo/client';
import { LocaleHelper } from '@guuru/helpers-common';
import { PlayCircleOutlined } from '@ant-design/icons';
import useCurrentUser from '../../../../../../../hooks/useCurrentUser';
import REACTIVATE_EXPERT from './mutations/ReactivateAccount';

const { localize } = LocaleHelper;

const ReactivateButton = function ({ partnerId, name }) {
  const { id: expertId } = useCurrentUser();
  const [
    reactivateExpert,
    { loading: isReactivating },
  ] = useMutation(REACTIVATE_EXPERT);

  const handleReactivateExpert = async () => {
    try {
      await reactivateExpert({
        variables: { id: expertId, partnerId },
      });
      success(`You are now enabled on ${localize(name)}`);
    } catch (e) {
      log.error(e);
      error(e.message);
    }
  };

  return (
    <Tooltip
      title="Your account is inactive as you have not accepted chats for more
      than 30 days. Click on this button if you want to reactivate your account."
    >
      <Button
        block
        size="middle"
        type="primary"
        loading={isReactivating}
        onClick={handleReactivateExpert}
        icon={<PlayCircleOutlined />}
      >
        Reactivate
      </Button>
    </Tooltip>
  );
};

ReactivateButton.propTypes = {
  partnerId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ReactivateButton;
