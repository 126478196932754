import React from 'react';
import styled from 'styled-components';
import { Grid, Layout, theme } from 'antd';
import ThemeProvider from '@guuru/theme-web';
import DrawerStack from '../../components/DrawerStack';
import ChatsList from '../../components/inbox/ActiveConversations';
import InboxHeader from '../../components/header';
import OnboardingModal from '../../components/Onboarding';

const { useToken } = theme;

const { useBreakpoint } = Grid;
const siders = {
  xxl: 470,
  xl: '30%',
  lg: 325,
  md: 325,
  sm: 325,
  xs: '85%',
};

// XXX(vieira): Workaround to unbreak the collapsed sidebar, should be fixed
// in the next antd release.
// See: https://github.com/ant-design/ant-design/issues/52674
const Sider = styled(Layout.Sider)`
  .ant-layout-sider-children {
    overflow: hidden;
  }
`;

const SidebarContainer = function () {
  const screens = useBreakpoint();
  const breakpoint = Object.keys(siders).find((size) => screens[size]);

  const width = siders[breakpoint];
  const { token } = useToken();

  return (
    <ThemeProvider
      theme={{ color: token.colorPrimary }}
      configProvider={{
        token: {
          colorIcon: token.colorWhite,
          colorLink: token.colorPrimary,
        },
        components: {
          Drawer: {
            boxShadowDrawerLeft: '1px 0px 0px 0 #f0f0f0',
            paddingLG: 0,
            padding: 0,
          },
          Typography: {
            colorTextHeading: token.colorWhite,
          },
          Card: {
            colorTextHeading: token.colorPrimary,
            borderRadiusLG: 0,
            lineWidth: 0,
            fontSizeLG: 14,
          },
          Rate: {
            marginXS: 4,
            controlHeightLG: 16.5,
          },
        },
      }}
    >
      <Sider
        breakpoint="lg"
        collapsedWidth={0}
        width={width || '30%'}
      >
        <DrawerStack>
          <OnboardingModal />
          <InboxHeader />
          <ChatsList />
        </DrawerStack>
      </Sider>
    </ThemeProvider>
  );
};

export default SidebarContainer;
