import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import log from '@guuru/logger-web';
import { client } from '@guuru/graphql-web';
import UPDATE_PUSH_TOKEN_WEB from './mutations/updatePushTokenWeb';

const PushConfig = (token, status = 'granted') => ({ token, status });

const checkPushStatus = async () => {
  if (!await isSupported()) {
    return 'unsupported';
  }
  return Notification.permission;
};

const getPushToken = async () => {
  try {
    const token = await getToken(getMessaging(), {
      vapidKey: process.env.FIREBASE_MESSAGING_VAPID_PUBLIC_KEY,
    });
    return PushConfig(token, Notification.permission);
  } catch (err) {
    log.error('Failed to obtain a web push token', err);
    return PushConfig(null, 'unsupported');
  }
};

const updatePushToken = async (uid, token) => {
  if (uid && token) {
    await client.mutate({
      mutation: UPDATE_PUSH_TOKEN_WEB,
      variables: {
        id: uid,
        pushTokenWeb: token,
      },
    });
  }
  return false;
};

export { checkPushStatus, updatePushToken, getPushToken };
