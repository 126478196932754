import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Rate, Row, Typography,
} from 'antd';
import { chatType } from '@guuru/react-types';
import { RetweetOutlined } from '@ant-design/icons';
import { ChatState } from '@guuru/constants-common';
import { scaleRating } from '@guuru/rating-common';
import { RATINGS } from '@guuru/chat-common';
import styled from 'styled-components';
import { ScoreBubble } from '@guuru/react-leaderboard';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import Time from './Time';
import ChatTooltip from './ChatTooltip';
import FeebackThumb from './FeebackThumb';

const { assigned, open } = ChatState;

const { Text } = Typography;

const TextEllipsis = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  display: block;
`;

const ItemContent = function ({ chat, highlighted = false }) {
  const { locale } = useCurrentUser();
  const {
    user,
    question,
    status,
    lastMessage,
    rating,
    isAutoRated,
    partner,
    hotTransfers,
    evaluation,
  } = chat;

  const {
    text: lastMessageText,
    author: {
      __typename: authorType,
    } = {},
  } = lastMessage || {};

  const isMessageFromUser = authorType === 'UserAuthor';
  const expertAuthor = lastMessage && !isMessageFromUser;
  const userAuthor = user?.name && `${user?.name}: `;
  const message = lastMessageText || question;
  const partnerName = partner.name?.[locale];

  return (
    <Col style={{ padding: '6px 0', paddingRight: 16 }}>
      <Row align="top" justify="space-between" wrap={false} gutter={12}>
        <Col flex="auto">
          <TextEllipsis style={{ fontSize: 16 }} strong={highlighted}>
            {partnerName}
          </TextEllipsis>
        </Col>
        <Col flex="none">
          <Row justify="end">
            <Time chat={chat} highlighted={highlighted} />
          </Row>
          <Row justify="end">
            {status === 'closed' && (
              <Text type="secondary" style={{ fontSize: 11, lineHeight: 1 }}>
                Waiting Rating
              </Text>
            )}
            {status === 'rated' && !isAutoRated && (
              <Rate
                value={scaleRating(RATINGS[rating])}
                allowHalf
                disabled
              />
            )}
          </Row>
        </Col>
        {(status === 'rated' && Number.isInteger(evaluation?.score)) && (
          <Col flex="none" style={{ paddingRight: 12 }}>
            <ScoreBubble
              height={30}
              score={evaluation.score}
              showLines={evaluation.score >= 8}
            />
          </Col>
        )}
      </Row>
      <Row align="middle" wrap={false} gutter={[6, 0]}>
        <FeebackThumb chat={chat} />
        {[assigned, open].includes(status) && !!hotTransfers.length && (
          <ChatTooltip title="Hot Transferred" placement="bottom">
            <RetweetOutlined style={{ color: 'red' }} justify="middle" />
          </ChatTooltip>
        )}
        <Col flex="auto">
          <TextEllipsis type="secondary" strong={highlighted}>
            {expertAuthor || userAuthor}
            {message}
          </TextEllipsis>
        </Col>
      </Row>
    </Col>
  );
};

ItemContent.propTypes = {
  chat: chatType.isRequired,
  highlighted: PropTypes.bool,
};

export default ItemContent;
