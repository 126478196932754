import {
  Col, Empty, Row, Typography,
} from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const { Text } = Typography;

const EmptyContent = function ({
  image = undefined,
  title = undefined,
  description = undefined,
}) {
  return (
    <Row gutter={[0, 80]}>
      <Col span={24} />
      <Col span={24}>
        <Empty
          image={image}
          styles={{
            image: { display: 'flex', justifyContent: 'center', fontSize: 75 },
          }}
          description={(
            <>
              <Col span={24}>{title}</Col>
              <Text type="secondary">{description}</Text>
            </>
      )}
        />
      </Col>
    </Row>
  );
};

EmptyContent.propTypes = {
  image: PropTypes.node,
  title: PropTypes.node,
  description: PropTypes.string,
};

export default EmptyContent;
