import { gql } from '@guuru/graphql-common/gql';
import { PARTNER_FIELDS } from '../fragments/PartnerFields';

export default gql(`#graphql
  subscription ExpertPartnersUpdated($id: ID!) {
    expertPartners(id: $id) {
      id
      partners {
        edges {
          node {
            ...PartnerFields
          }
        }
      }
    }
  }
`, [PARTNER_FIELDS]);
