import { gql } from '@guuru/graphql-common/gql';
import { PARTNER_FIELDS } from './PartnerFields';

// eslint-disable-next-line import/prefer-default-export
export const SUBMISSION_FIELDS = gql(`#graphql
  fragment SubmissionFields on Submission {
    createdAt
    partner {
      ...PartnerFields
    }
  }
`, [PARTNER_FIELDS]);
