import { useEffect } from 'react';
import orderBy from 'lodash/orderBy';
import { useLiveQuery } from '@guuru/react-hooks';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import GET_EXPERT_PARTNERS from './queries/GetExpertPartners';
import EXPERT_PARTNERS_UPDATED from './subscriptions/ExpertPartnersUpdated';

const useExpertPartners = function (skipSubscription) {
  const { id: expertId } = useCurrentUser();
  const {
    loading,
    refetch,
    fetchMore,
    data: {
      expert: {
        locale,
        partners: {
          pageInfo,
          edges: expertPartners = [],
        } = {},
        submissions: {
          edges: pendingSubmissions = [],
        } = {},
      } = {},
    } = {},
  } = useLiveQuery(GET_EXPERT_PARTNERS, EXPERT_PARTNERS_UPDATED, {
    variables: { id: expertId },
    subscriptionOptions: { skip: skipSubscription },
  });

  const submissions = (pendingSubmissions ?? [])
    .map(({ node: { createdAt, partner } }) => ({
      node: partner, submittedAt: createdAt, bucket: 'pending',
    }));

  useEffect(() => {
    if (!skipSubscription) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    expertPartners: orderBy([...expertPartners, ...submissions], 'joinedAt', 'desc'),
    count: expertPartners.length + pendingSubmissions.length,
    fetchMore,
    pageInfo,
    locale: locale ?? 'en',
    submissions,
    loading,
    refetch,
  };
};

export default useExpertPartners;
