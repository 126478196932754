import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const STORE_KEY = 'partner-referrer';

const useStoreInvitationPartner = () => {
  const [params] = useSearchParams();

  useEffect(() => {
    if (params?.has('~customer_campaign')) {
      localStorage.setItem(STORE_KEY, params.get('~customer_campaign'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const removeInvitationPartner = () => {
  localStorage.removeItem(STORE_KEY);
};

const getInvitationPartner = () => localStorage.getItem(STORE_KEY);

export {
  useStoreInvitationPartner,
  getInvitationPartner,
  removeInvitationPartner,
};
