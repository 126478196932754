import React from 'react';
import { Space, Typography } from 'antd';

const { Text } = Typography;

const Communities = function () {
  return (
    <Space direction="vertical" align="center">
      <Text>Your account is all set! 🎉</Text>
      <Text>
        Now, let&#39;s connect you with the right community.
        After you click &#34;Done&#34;, you&#39;ll see your Community
        and you can begin your journey as a Guuru.
      </Text>
    </Space>
  );
};

export default Communities;
