import React from 'react';
import { List } from 'antd';
import { LoadMore } from '@guuru/react-pagination';
import Empty from '../../../Empty';
import Accept from '../../../../assets/images/Accept.svg';
import useExpertPartners from '../hooks/useExpertPartners';
import CommunityListItem from '../CommunityListItem';

const JoinedCommunitiesList = function () {
  const {
    expertPartners,
    pageInfo,
    fetchMore,
    loading,
  } = useExpertPartners();

  return (
    <List
      itemLayout="vertical"
      dataSource={expertPartners}
      loading={loading}
      loadMore={(
        <LoadMore
          data={{ pageInfo }}
          fetchMore={fetchMore}
          loading={loading}
        />
      )}
      locale={{
        emptyText: (
          <Empty
            image={<Accept />}
            title="Are you not part of a community yet?"
            description="Start by clicking on the + to see which brands are currently looking for enthusiasts and product experts to join their communities on their mission to provide the best customer to customer service"
          />
        ),
      }}
      renderItem={(expertPartner) => (
        <CommunityListItem partner={expertPartner} />
      )}
    />
  );
};

export default JoinedCommunitiesList;
