import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Typography,
  Input,
  Button,
  Col,
  Row,
} from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import FormItem from '../FormItem';
import ErrorMessages from '../ErrorMessages';
import SEND_RECOVERY_EMAIL from './mutations/sendRecoveryEmail';
import RecoverEmailMessage from './RecoverEmailMessage';

const { Paragraph } = Typography;

const ForgotPassword = function ({ origin = null }) {
  const [isRecoveryEmailSent, setIsRecoveryEmailSent] = useState(false);
  const [sendRecoveryEmail, { loading: isSubmitting, error }] = (
    useMutation(SEND_RECOVERY_EMAIL, {
      onCompleted: () => setIsRecoveryEmailSent(true),
      onError: () => {},
    })
  );

  const handleSubmit = (values) => (
    sendRecoveryEmail({
      variables: {
        email: values.email,
        origin,
      },
    })
  );

  if (isRecoveryEmailSent) {
    return (
      <RecoverEmailMessage hide={() => setIsRecoveryEmailSent(false)} />
    );
  }

  return (
    <>
      {error && (
        <ErrorMessages
          message={error.message ?? 'Please try again later or contact Guuru Support'}
        />
      )}
      <Paragraph>Forgot password?</Paragraph>
      <Form
        layout="vertical"
        className="login-form"
        onFinish={handleSubmit}
      >
        <FormItem
          name="email"
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: 'Please type your email address',
            },
            {
              type: 'email',
              message: 'Please type a valid email address',
            },
          ]}
        >
          <Input
            size="large"
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Your Email"
            autoComplete="username"
            autoFocus
          />
        </FormItem>
        <FormItem>
          <Row gutter={[16, 8]}>
            <Col span={24} lg={12}>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
              >
                Continue
              </Button>
            </Col>
            <Col span={24} lg={12}>
              <Link to="/signin">
                <Button block>Back</Button>
              </Link>
            </Col>
          </Row>
        </FormItem>
      </Form>
    </>
  );
};

ForgotPassword.propTypes = {
  origin: PropTypes.string,
};

export default ForgotPassword;
