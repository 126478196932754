import React, { useEffect } from 'react';
import { MobileOutlined } from '@ant-design/icons';
import {
  App as AntContext,
  Space,
  Button,
} from 'antd';
import useCurrentUser from '../../../../hooks/useCurrentUser';

const useNotifications = function () {
  const { notification } = AntContext.useApp();
  const user = useCurrentUser();

  const handleDismiss = () => {
    localStorage.setItem('guuru-install-app-dismissed', true);
    notification.destroy('install-app');
  };

  const showNotification = () => {
    notification.info({
      message: 'Receive notifications anywhere and anytime',
      description: 'Install our mobile app to receive real-time notifications wherever you are.',
      btn: (
        <Space>
          <Button onClick={handleDismiss}>
            Don&apos;t Show Again
          </Button>
          <Button
            type="primary"
            href="https://guuru.app.link/install"
            target="_blank"
          >
            Install
          </Button>
        </Space>
      ),
      key: 'install-app',
      duration: 0,
      icon: <MobileOutlined style={{ color: '#108ee9' }} />,
      style: { width: 560 },
    });
  };

  useEffect(() => {
    const isGuuru = !!user?.roles?.some((r) => r.role === 'guuru');
    const hasDismissed = localStorage.getItem('guuru-install-app-dismissed');

    if (isGuuru && !user.pushToken && !hasDismissed) {
      showNotification();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useNotifications;
