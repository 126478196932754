import React, { useCallback, useEffect } from 'react';
import { checkPushStatus, getPushToken, updatePushToken } from '@guuru/fcm-web';
import { App as AntContext, Button, Space } from 'antd';
import useCurrentUser from '../../../../hooks/useCurrentUser';

const useNotificationsCheck = function () {
  const user = useCurrentUser();
  const { notification } = AntContext.useApp();

  const handleDismiss = () => {
    localStorage.setItem('guuru-notifications-dismissed', true);
    notification.destroy('push-notifications');
  };

  const handleEnableNotifications = useCallback(() => {
    notification.destroy('push-notifications');

    getPushToken().then((push) => {
      if (push.status === 'granted') {
        if (push.token === user.pushTokenWeb) return;
        updatePushToken(user.id, push.token);
      } else {
        // eslint-disable-next-line no-use-before-define
        showNotification(push.status);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification, user]);

  const showNotification = useCallback((status) => {
    const hasDismissed = localStorage.getItem('guuru-notifications-dismissed');
    const warning = {
      message: 'Notifications are disabled',
      description: 'We need your permission to enable notifications for chats and messages.',
      key: 'push-notifications',
      duration: 0,
      style: { width: 560 },
    };

    switch (status) {
      case 'default':
      case 'granted':
        notification.warning({
          ...warning,
          actions: (
            <Button type="primary" onClick={handleEnableNotifications}>
              Enable
            </Button>
          ),
        });
        break;
      case 'unsupported':
        if (hasDismissed) break;

        notification.warning({
          ...warning,
          description: 'This browser does not support push notifications.',
          actions: (
            <Space>
              <Button onClick={handleDismiss}>
                Don&apos;t Show Again
              </Button>
              <Button
                type="primary"
                target="_blank"
                href="https://caniuse.com/#feat=push-api"
              >
                Learn More
              </Button>
            </Space>
          ),
        });
        break;
      case 'denied':
      default:
        if (hasDismissed) break;

        notification.warning({
          ...warning,
          message: 'You have disallowed notifications in your browser',
          description: 'You will need to open your browser preferences to change that.',
          actions: (
            <Space>
              <Button onClick={handleDismiss}>
                Don&apos;t Show Again
              </Button>
              <Button
                type="primary"
                target="_blank"
                href="https://bit.ly/2ohQsDq"
              >
                Learn More
              </Button>
            </Space>
          ),
        });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification, handleEnableNotifications]);

  useEffect(() => {
    if (!user.uid || !user.isAccountVerified) return;

    checkPushStatus().then((status) => {
      if (status === 'granted') {
        handleEnableNotifications();
      } else {
        showNotification(status);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, handleEnableNotifications]);
};

export default useNotificationsCheck;
