import { gql } from '@guuru/graphql-common/gql';
import { SUBMISSION_FIELDS } from '../fragments/SubmissionFields';
import { EXPERT_PARTNER_FIELDS } from '../fragments/ExpertPartnerFields';

export default gql(`#graphql
  subscription ExpertPartners($id: ID!) {
    expertPartners(id: $id) {
      id
      submissions(
        first: 10
        filters: [
          {key: status, value: ["pending"]},
          {key: partnerStatus, value: "active"},
        ]
      ) {
        edges {
          node {
            ...SubmissionFields
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      partners {
        edges {
          ...ExpertPartnerFields
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`, [EXPERT_PARTNER_FIELDS, SUBMISSION_FIELDS]);
