import React, { useRef, useState } from 'react';
import { Col, Image } from 'antd';
import Player from 'react-player';
import styled from 'styled-components';

const welcomeVideo = 'https://cdn.guuru.com/assets/videos/guuru-welcome.mp4';
const welcomeThumbnail = 'https://cdn.guuru.com/assets/images/guuru-welcome-thumbnail.jpg';

type VideoRef = {
  // eslint-disable-next-line no-unused-vars
  seekTo: (duration: number) => void;
  showPreview: () => void;
};

const Video = styled(Player)`
  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
`;

const Intro = function () {
  const [play, setPlay] = useState(false);
  const videoRef = useRef<VideoRef>();

  const onEnded = () => {
    videoRef.current?.seekTo(0);
    videoRef.current?.showPreview();
  };

  return (
    <Col style={{ overflow: 'hidden' }}>
      <Video
        onContextMenu={(e:any) => e.preventDefault()}
        // @ts-ignore
        ref={videoRef}
        playing={play}
        controls={play}
        url={welcomeVideo}
        light={(
          <Image
            src={welcomeThumbnail}
            onClick={() => setPlay(true)}
            preview={false}
          />
        )}
        width="100%"
        height={600}
        /* eslint-disable-next-line react/jsx-no-useless-fragment */
        playIcon={<></>}
        config={{
          file: {
            forceVideo: true,
            attributes: {
              disablepictureinpicture: 'true',
              controlslist: 'nodownload nofullscreen noplaybackrate',
            },
          },
        }}
        onEnded={onEnded}
      />
    </Col>
  );
};

export default Intro;
