import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { auth } from '@guuru/auth-web';
import GET_EXPERT_PROFILE from './queries/getExpertProfile';

const useFirebaseCurrentUserId = () => {
  const [authUser, setAuthUser] = useState(false);
  useEffect(() => auth.onAuthStateChanged(setAuthUser), []);
  return { userId: authUser?.uid, loading: authUser === false };
};

export default () => {
  const { userId, loading: isLoadingFirebaseUser } = useFirebaseCurrentUserId();
  const [signInErrorCode, setSignInErrorCode] = useState();

  const {
    loading: isLoadingUser,
    data: {
      expert: user = {},
    } = {},
  } = useQuery(GET_EXPERT_PROFILE, {
    skip: !userId,
    variables: {
      id: userId,
    },
  });

  if (userId && !user?.id) {
    if (!signInErrorCode) {
      setSignInErrorCode('auth/access-denied');
    }
  } else if (user?.id && signInErrorCode) {
    setSignInErrorCode(null);
  }

  const loading = isLoadingUser || isLoadingFirebaseUser;
  return {
    loading,
    user: {
      ...user,
      locale: user?.locale || 'en',
      uid: !loading ? user?.id : undefined,
      isAccountVerified: !!user?.isEmailVerified,
      isTosAccepted: !!user?.account?.tosAcceptedAt,
    },
    signInErrorCode,
  };
};
