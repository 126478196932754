import { gql } from '@guuru/graphql-common/gql';

export default gql(`#graphql
  mutation ReactivateExpertAccount($id: ID!, $partnerId: ID!) {
    reActivateExpert(id: $id, partnerId: $partnerId) {
      id
      partners {
        edges {
          node {
            id
          }
          isEnabled
          joinedAt
          bucket
          scores {
            score
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`);
