import { gql } from '@guuru/graphql-common/gql';
import { PARTNER_FIELDS } from './PartnerFields';

// eslint-disable-next-line import/prefer-default-export
export const EXPERT_PARTNER_FIELDS = gql(`#graphql
  fragment ExpertPartnerFields on ExpertPartnersEdge {
    node {
      ...PartnerFields
    }
    isEnabled
    joinedAt
    categories {
      node {
        id
        name
        requiresVerify
      }
      isEnabled
    }
    bucket
    roles
    bio
  }
`, [PARTNER_FIELDS]);
