import React from 'react';
import {
  Button,
  Col,
  Row,
  Typography,
} from 'antd';
import { ScoreBubble } from '@guuru/react-leaderboard';
import ExpertCommunity from '../../../../../assets/images/ExpertCommunity.svg';
import Profile from '../../../../../assets/images/Profile.svg';
import KnowledgeBase from '../../../../../assets/images/KnowledgeBase.svg';
import Trophy from '../../../../../assets/images/Trophy.svg';
import useDrawers from '../../../../../hooks/useDrawers';
import ExpertProfileView from '../../../../../views/drawers/YouView';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useExpertPartners from '../../hooks/useExpertPartners';

const { Text } = Typography;

interface CommunityButtonsProps {
  partner: {
    node: {
      id: string,
    },
    bucket: string,
    scores: {
      score: number,
    },
  },
}

const handleOpenCommunityPage = (partnerId: string, utmSource: string) => {
  window.open(`${process.env.COMMUNITY_ENDPOINT}/${partnerId}/guurus/?utm_source=${utmSource}`);
};

const handleOpenKnowledgeBase = (partnerId: string, languageCode: string) => {
  window.open(`${process.env.PAGES_URL}/${partnerId}/articles?locale=${languageCode}`);
};

const handleOpenLeaderboard = (
  partnerId: string,
  { utmSource, expertId }: {utmSource: string; expertId: string},
) => {
  window.open(`${process.env.COMMUNITY_ENDPOINT}/${partnerId}/leaderboard?id=${expertId}&utm_source=${utmSource}`);
};

const CommunityButtons = function ({ partner }: CommunityButtonsProps) {
  const { locale } = useExpertPartners();
  // @ts-ignore
  const { id } = useCurrentUser();
  const { open } = useDrawers();

  const {
    node: { id: partnerId },
    bucket,
    scores: { score = null } = {},
  } = partner;

  const showProfile = ['active', 'onboarding', 'inactive'].includes(bucket);

  const communityButtons = showProfile ? [
    {
      name: 'Members',
      icon: <ExpertCommunity />,
      onClick: () => { handleOpenCommunityPage(partnerId, 'web_community_view'); },
    },
    {
      name: 'You',
      icon: <Profile />,
      // @ts-ignore
      onClick: () => open(ExpertProfileView, { partner }),
    },
    {
      name: 'Knowledge',
      icon: <KnowledgeBase />,
      onClick: () => { handleOpenKnowledgeBase(partnerId, locale); },
    },
    {
      name: 'Score',
      icon: (
        <>
          {score !== null && (
            <div style={{ position: 'absolute', top: '-25px', right: '-5px' }}>
              <ScoreBubble height={36} fontSize={12} score={score} />
            </div>
          )}
          <Trophy />
        </>
      ),
      onClick: () => handleOpenLeaderboard(partnerId, {
        utmSource: 'web_community_view',
        expertId: id,
      }),
    },
  ] : [
    {
      name: 'Members',
      icon: <ExpertCommunity />,
      onClick: () => { handleOpenCommunityPage(partnerId, 'web_community_view'); },
    },
    {
      name: 'Knowledge',
      icon: <KnowledgeBase />,
      onClick: () => { handleOpenKnowledgeBase(partnerId, locale); },
    },
  ];

  return (
    <Row justify="center" gutter={[0, 24]}>
      {communityButtons.map(({ name, icon, onClick }) => (
        <Col key={name} xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            key={name}
            type="text"
            icon={icon}
            onClick={onClick}
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <Text>{name}</Text>
          </Button>
        </Col>
      ))}
    </Row>
  );
};

export default CommunityButtons;
