import { gql } from '@guuru/graphql-common/gql';
import { PARTNER_FIELDS } from '../fragments/PartnerFields';

export default gql(`#graphql
  query GetActiveVisiblePartners(
    $filters: [PartnerFilterType]
    $first: Int
    $after: String
  ) {
    partners(filters: $filters, first: $first, after: $after) {
      edges {
        node {
          ...PartnerFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`, [PARTNER_FIELDS]);
