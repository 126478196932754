import React from 'react';
import { Card, List } from 'antd';
import { useSubscription, useSuspenseQuery } from '@apollo/client';
import { LoadMore } from '@guuru/react-pagination';
import { useSuspenseTranslations } from '@guuru/react-hooks';
import GuuruShrugs from '../../../../assets/images/GuuruShrugs.svg';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import Empty from '../../../Empty';
import CommunityListItem from '../CommunityListItem';
import GET_VISIBLE_PARTNERS from './queries/GetActiveVisiblePartners';
import EXPERT_PARTNERS_UPDATED from './subscriptions/ExpertPartnersUpdated';

const OpenCommunitiesList = function () {
  const { id: expertId } = useCurrentUser();

  const {
    loading,
    refetch,
    fetchMore,
    data: {
      partners: {
        pageInfo,
        edges: partners = [],
      } = {},
    } = {},
  } = useSuspenseQuery(GET_VISIBLE_PARTNERS, {
    variables: {
      filters: [{ key: 'visibleRecruitment', value: { expertId } }],
      first: 10,
      after: null,
    },
    // TODO(vieira): This should no longer be needed as the subscription now
    // returns the same fields as the query.
    fetchPolicy: 'network-only',
  });

  useSubscription(EXPERT_PARTNERS_UPDATED, {
    variables: { id: expertId },
    // TODO(vieira): This should no longer be needed as the subscription now
    // returns the same fields as the query.
    onData: () => refetch(),
  });

  const {
    data: {
      joinCommunitiesEmptyHeader = '',
      joinCommunitiesEmptyDescription = '',
    } = {},
  } = useSuspenseTranslations(
    ['joinCommunitiesEmptyHeader', 'joinCommunitiesEmptyDescription'],
  );

  return !partners.length ? (
    <Card>
      <Empty
        image={<GuuruShrugs />}
        title={joinCommunitiesEmptyHeader}
        description={joinCommunitiesEmptyDescription}
      />
    </Card>
  ) : (
    <List
      itemLayout="vertical"
      dataSource={partners}
      loading={loading}
      loadMore={(
        <LoadMore
          data={{ pageInfo }}
          fetchMore={fetchMore}
          loading={loading}
        />
      )}
      renderItem={(partner) => (
        <CommunityListItem partner={partner} />
      )}
    />
  );
};

export default OpenCommunitiesList;
